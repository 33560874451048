import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';
import lifePrintBanner from '../../assets/images/lifeprint/lifeprint-banner.webp';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import lifeprintOne from '../../assets/images/lifeprint/lifeprint-1.png';
import lifeprintDemoOne from '../../assets/images/lifeprint/lifeprint-demo-1.webp';
import lifeprintDemoTwo from '../../assets/images/lifeprint/lifeprint-demo-2.webp';
import lifeprintArchiOne from '../../assets/images/lifeprint/lifeprint-archit-1.webp';
import lifeprintArchiTwo from '../../assets/images/lifeprint/lifeprint-archit-2.webp';
import lifeprintHpOne from '../../assets/images/lifeprint/lifeprint-hp-1.png';
import lifeprintHpTwo from '../../assets/images/lifeprint/lifeprint-hp-2.gif';

import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Lifeprint extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Portable Photo Printer with AR & Social Networking
                </div>
                <div className="subtitle section-margin">
                  Bringing printed photographs back into the mainstream through an augmented reality
                  experience
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#67BEA3'}}> LIFEPRINT</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build an app that allows users to instantly print AR photos and videos using
                      Lifeprint printers and share that content with their social network.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed the native iOS and Android apps with printing (Bluetooth & WiFi) and
                      scanning (AR experience) modules and a globally supported cloud
                      infrastructure.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      Our work helped Lifeprint become Apple’s global retail partner, bag a
                      lucrative Harry Potter license from Warner Bros., and raise $2.2 million over
                      three funding rounds.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://techcrunch.com/2014/03/18/lifeprints-app-connected-wireless-printer-makes-its-kickstarter-debut/?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAKEbVQvtsHTAUsPfzykxw2hSIerMpawVi7LxrAz6-307Y5vHgUGYOQQzc20WBgydTb27BmswTQ6FL87STp44d98hGYhMKTYiroaICvxHOpTBLd74HL0sELxD_LV1gtMO0VxBs2IX3YX19tA27ZgOEn97gXZjGq1BvK1OSx3K5dEO&guccounter=2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lifeprint's "app-connected" wireless printer makes its Kickstarter debut
                    </a>{' '}
                    - TechCrunch
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.theverge.com/circuitbreaker/2018/10/2/17921132/lifeprint-harry-potter-augmented-reality-photos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lifeprint’s augmented reality photos get the most obvious Harry Potter
                      partnership Ever
                    </a>{' '}
                    - The Verge
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/X_hIZI7ZA4I?si=8uA0gidhAVKtXO2g"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://lifeprintphotos.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lifeprint
                      </a>{' '}
                      approached Tintash with the vision of building an app-controlled augmented
                      reality photo and video printer. With Facebook, Instagram, and Snapchat
                      dominating the “memories” market, Lifeprint wanted to bring back the value of
                      preserving memories through printed photographs. They wanted to let users
                      share and experience digital content like it was 1999 – but with a totally new
                      twist.
                    </p>
                    <br />
                    <p className="description">
                      Lifeprint’s AR printers would allow users to print “hyperphotos” and see them
                      come to life before their eyes. Users could shoot a short video clip of 15
                      seconds or less, or grab a GIF from their social media feeds, and print it as
                      a hyperphoto (one frame, which users could manually choose, was designated as
                      the “photo” part of things). When someone printed that hyperphoto, pointing
                      their smartphone camera at it triggered the associated video clip to play
                      within the Lifeprint app.
                    </p>
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Tintash’s partnership with Lifeprint spanned multiple phases over several
                      years during which we worked on different feature sets to achieve the various
                      milestones in their product journey.
                    </p>
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 1
                    </p>
                    <p className="description">
                      We started with the idea of creating an app that would enable users to
                      instantly print photos and videos in their phones anywhere using Lifeprint’s
                      proprietary portable printer. The Lifeprint team wanted us to convert that
                      idea into a proof of concept (POC) to help with their fundraising efforts.
                    </p>
                    <br />
                    <p className="description">
                      In a collaboration that started right at the beginning of Lifeprint’s product
                      journey, Tintash helped in planning and scoping out the app functionality,
                      defining the user flows, prototyping and development. The final POC allowed
                      users to print photos and videos in their Lifeprint app gallery using the
                      Lifeprint printer that was connected to their phones via Bluetooth. Users
                      could also play the printed videos on their mobile screens by scanning those
                      through the app.
                    </p>
                    <br />
                    <p className="description">
                      Lifeprint used the demonstrable application to run successful Kickstarter and
                      Indiegogo campaigns and raise upwards of $300k in seed funding. This
                      successful fundraising afforded Lifeprint the capital to manufacture printers
                      and continue with their product development efforts.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12 p-0">
                      <img className="w-100  " src={lifeprintOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 2
                    </p>
                    <p className="description">
                      Following a successful seed fundraising campaign, Lifeprint landed an
                      opportunity to give a product demo to Apple’s executives. To prepare for the
                      meeting, we started working on scaling the app to support reliable printing
                      and scanning of user generated content.
                    </p>
                    <br />
                    <p className="description">
                      It was important to have both a reliable printing module, which served the
                      core functionality of printing, as well as a robust scanning module to ensure
                      a great overall user experience. For the former, our team created a state
                      machine; while for the latter, we developed an image recognition and playback
                      experience that could support recognition of thousands of distinct user images
                      in a timely manner.
                    </p>
                    <br />
                    <p className="description">
                      In line with Lifeprint’s vision of enabling people to share their memories
                      with friends and family, we created the Lifeprint social network within the
                      app where users could share, like, and comment on content. They could also
                      print photos shared with them and scan those through the app to bring them to
                      life. The team also worked on integrations to allow users to fetch content
                      from their Facebook, Instagram and Twitter albums on to their Lifeprint app.
                    </p>
                    <br />
                    <p className="description">
                      All these efforts aimed at scaling the POC helped Lifeprint become a global
                      retail partner of Apple.
                    </p>
                    <br />
                  </div>
                  <div className="text-wrap-container col-12 p-3 d-flex section-margin">
                    <div className="col-lg-2 p-0 d-flex align-items-center order-1 order-lg-1">
                      <div className="image-description text-center mb-0">
                        Lifeprint’s demo stations at local Apple stores
                      </div>
                    </div>
                    <div className="col-lg-5 p-0 mr-3 d-flex justify-content-center order-2 order-lg-2">
                      <img className="wrap-image" src={lifeprintDemoOne} alt="raven" />
                    </div>

                    <div className="col-lg-5 p-0 d-flex justify-content-center order-3 order-lg-3">
                      <img className="wrap-image" src={lifeprintDemoTwo} alt="raven" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 3
                    </p>
                    <p className="description">
                      Becoming Apple’s retail partner entailed making the app globally available,
                      including China. China was a huge market that Lifeprint wanted to tap into.
                      However, the country’s firewall policy which blocked most of Google's cloud
                      services that our app used posed a challenge.
                    </p>
                    <br />
                    <p className="description">
                      To overcome this problem and include the Chinese user base, our engineers
                      deployed the same python codebase to the AWS and app scale infrastructure.
                      Using a single code for multiple deployment environments, we developed an
                      infrastructure that was easily scalable and maintainable and worked globally.
                    </p>
                    <br />
                    <p className="description">
                      Updating the infrastructure required updating the overall app architecture.
                      The diagrams below compare different modules of the service architecture based
                      on the user’s region.
                    </p>
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="col-lg-6 col-sm-9 my-3">
                        <img className="w-100" src={lifeprintArchiOne} alt="Lifeprint" />
                        <div className="image-description-small text-center my-3">
                          Lifeprint’s architecture for global users
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-9 my-3">
                        <img className="w-100" src={lifeprintArchiTwo} alt="Lifeprint" />
                        <div className="image-description-small text-center my-3">
                          The comparable architecture for users in China
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      In addition to launching the global app support, the team also worked on
                      scaling the AR experience. We used the new AR library (CraftAR) to allow users
                      to scan their desired content from within more than a million different user
                      generated images in just a few milliseconds. We also used DeepAR (another 3rd
                      party service) to enable an experience similar to Snapchat masks and filters.
                    </p>
                    <br />
                    <p className="description">
                      During this phase, Lifeprint launched a new printer model which allowed for
                      WiFi printing in addition to the Bluetooth connectivity. This hardware update
                      required our team to make changes to the app’s printer module architecture so
                      that WiFi-to-Bluetooth and Bluetooth-to-Wifi transitions could be handled
                      seamlessly. In order to pre-empt the possibilities of data corruption and
                      undefined states during such hand-offs, we used a comprehensive state machine
                      pattern to solve that complex problem.
                    </p>
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 4
                    </p>
                    <p className="description">
                      In 2018,{' '}
                      <a
                        className="description"
                        href="https://www.theverge.com/circuitbreaker/2018/10/2/17921132/lifeprint-harry-potter-augmented-reality-photos"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lifeprint partnered with Warner Bros.
                      </a>{' '}
                      to bundle their very Harry Potter-like moving AR pictures into a proper Harry
                      Potter-branded package. The company launched their latest product, the Harry
                      Potter Magic Photo and Video Printer, that allowed users to unlock Harry
                      Potter-themed content inside the app by simply connecting to this printer.
                    </p>
                    <br />
                    <p className="description">
                      Implementing this Harry Potter theme required our team to implement
                      hardware-based content unlocking as well as build considerable updates to the
                      overall application’s user interface and user experience design. Towards this
                      end, we used firebase to update user data in real-time and added a theme
                      engine using protocol oriented programming (PoP) to handle all the UI/UX
                      updates as soon as a user connected to the special Harry Potter Printer.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-6 col-sm-9 p-0 pr-1">
                      <img className="w-100" src={lifeprintHpOne} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6 col-sm-9 p-0 pl-1">
                      <img className="w-100" src={lifeprintHpTwo} alt="bed bath and beyond" />
                    </div>
                    <div className="image-description text-center mt-5 mb-3 col-12">
                      The special Harry Potter-themed Lifeprint printer & AR experience
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      Lifeprint was the first (and only) instant photo printer in the world that not
                      only allowed users to print live videos, but also share those printed videos
                      with their friends, family, and fans all over the world.
                    </p>
                    <br />
                    <p className="description">
                      It’s also super simple to use. All users needed was a Lifeprint wireless,
                      inkless photo printer, the augmented reality Hyperphoto™ software application,
                      a sticky back photo paper, and a Lifeprint-compatible smartphone (iPhone or
                      Android). They could select photos, videos, and even animated GIFs from their
                      phone gallery, Snapchat, Instagram, Facebook, GoPro Clips, and Apple Live
                      Photos and simply hit print.
                    </p>
                    <br />
                    <p className="description">
                      Once the photo was quickly printed, users could view it through Lifeprint’s
                      Hyperphoto software app and watch the image come to life in their hands. The
                      app also collected and organized photos and videos from their phones’ camera
                      roll, making them easy to access and send through social media to other
                      Lifeprint users. Once your friends had your prints, they could watch them come
                      to life just as you could watch theirs.
                    </p>
                    <br />
                    <p className="description">
                      While there were many Zink printers in the market, we created an app that
                      allowed users to make AR experiences from their photos and videos. In one
                      meeting, an Apple executive noted that while their company had pushed on the
                      AR front for a couple of years, only Lifeprint had managed to enable users to
                      create personalized experiences using their own media.
                    </p>
                    <br />
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      We were extremely satisfied with the results achieved. Tintash have great
                      communications, great attention to detail, and are firmly committed to their
                      delivery goals. When problems were identified, they were very creative at
                      suggesting fixes and alternatives. They were very responsive and aggressive in
                      closing out defect reports. We had a very positive work experience and a very
                      positive relationship.
                    </p>
                    <h2 className="review-name mt-3" style={{fontWeight: 'bold'}}>
                      Patrick Cosgrove
                    </h2>
                    <p className="review-name m-0">
                      CTO, Lifeprint on{' '}
                      <a
                        href="https://clutch.co/profile/tintash?page=3#review-26900"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clutch
                      </a>
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Efforts of the Tintash team throughout the course helped Lifeprint displace
                      existing Zinc printers in the Apple Store, nurture a passionate and growing
                      photo sharing community, and successfully raise $2.2 million over three
                      funding rounds.
                    </p>
                    <br />
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Lifeprint;
